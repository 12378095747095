<template>
    <div class="task">
        <Loading v-if="loading"></Loading>
        <!-- 添加二级分类 -->
        <a-modal
            title="新增分类"
            :visible="parentClass.visible"
            @ok="handleOk"
            :confirmLoading="parentClass.confirmLoading"
            @cancel="parentClass.visible = false"
            v-if="parentClass.visible"
            centered
        >
            <a-form :form="form">
                <a-form-item label="分类" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" v-if="classType == 1">
                    <a-select v-decorator="['id', { rules: [{ required: true, message: '请选择分类!' }] }]">
                        <a-select-option :value="item.id" v-for="(item,index) in classB" :key="index">{{item.name}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="分类名" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                    <a-input
                        v-decorator="['name', { rules: [{ required: true, message: '请输入分类名!' }] }]"
                    />
                </a-form-item>
            </a-form>
        </a-modal>
        <div class="clearfix">
            <div class="fL">
                <a-button class="btn" style="margin-right:20px" :class="{active:index+1 == id}" v-for="(item,index) in classB" :key="index" @click="changeClass(item)">{{item.name}}</a-button>
            </div>
            <div class="fR">
                 <a-button class="btn fR" @click="AddClass(null, 1)">新增分类</a-button>
            </div>
        </div>
        <div v-if="!list.length && !loading" style="line-height:200px;font-size:20px;text-align:center">暂无数据</div>
        <div style="padding-top:40px;min-height:600px" v-if="list.length && !loading">
                <div style="margin-bottom:15px" v-for="(item, index) in list" :key="index">
                    <div class="examList" :class="{active:item.show}">
                        <div>
                            <!-- <label class="z_checkbox"><input type="checkbox"><span></span></label> -->
                            <span class="num">{{index | num}}</span>
                            <span class="name">{{item.cname}}</span>
                        </div>
                        <div class="iconGroup">
                            <div class="csP" @click="AddClass(item, 2)">添加</div>
                            <div class="csP">
                                <a-popconfirm
                                    title="确定删除该类?"
                                    @confirm="deletClass(item.id)"
                                    okText="确定"
                                    cancelText="取消"
                                >
                                删除
                                </a-popconfirm>
                            </div>
                            <div style="width:48px">
                                <a-icon type="right" @click="toggle(item,index)" class="transformIcon" :class="{active:item.show}" v-if="item.son.length"/>
                            </div>
                        </div>
                    </div>
                    <transition name="slide-fade">
                        <div v-if="item.show" class="sonList">
                            <div class="clearfix sonItem" v-for="(el,indexS) in item.son" :key="indexS">
                                <span class="fL">{{el.cname}}</span>
                                <span class="fR">
                                    <a-popconfirm
                                        title="确定删除该类?"
                                        @confirm="deletClass(el.id)"
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <icon-font type="icon-shanchu" title="删除" style="color:#f5222d;font-size:20px;" class="csP"/>
                                    </a-popconfirm>
                                    <a-icon type="right" @click="toggle(item,index)" class="transformIcon" :class="{active:item.show}"/>
                                </span>
                            </div>
                        </div>
                    </transition>
                </div>
        </div>
        <a-pagination v-model="current" :total="total" v-if="total>0"/>
    </div>
</template>
<script>
import Loading from '@/components/loading'
import { getclasses, addclasses, delClasses } from '@/libs/api'
import { IconFont } from '@/libs/IconFont'
import { classB } from '@/libs/transform'
export default {
    name:'setting',
    components:{ Loading, IconFont },
    data(){
        return{
            form: this.$form.createForm(this),
            currentId:'',
            classType:1,
            parentClass:{
                visible:false,
                confirmLoading:false
            },
            id:1,
            loading:false,
            total:'',
            list:[],
            classB,
            pagination:{
                page:1,
                size:10
            }
        }
    },
    filters:{
        num(val){
            if (val<9){
                return `0${val+1}`
            }else{
                return val
            }
        }
    },
    mounted(){
        this.getList()
    },
    methods:{
        handleOk(e){
            e.preventDefault();
            this.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    this.parentClass.confirmLoading = true
                    let request = {}
                    if(this.classType == 1){
                        request = { ...values }
                    }else if(this.classType == 2){
                         request = { id:this.currentId, ...values }
                    }
                    addclasses(request).then(res=>{
                        this.getList()
                        this.parentClass.visible = false
                        this.parentClass.confirmLoading = false
                    },error=>{
                        console.log(error)
                         this.parentClass.confirmLoading = false
                    })
                }
            });
        },
        getList(){
            this.loading = true
            this.list = []
            getclasses({id:this.id}).then(res=>{
                this.loading = false
                if(res.length){
                    res.forEach(el=>{
                        el.show = false
                    })
                    this.list = res
                }
            },error=>{
                this.loading = false
                console.log(error)
            })
        },
        changeClass({id}){
            this.id = id
            this.getList()
        },
        toggle(item,index){
            this.list[index].show = !item.show
        },
        deletClass(id){
           delClasses({id}).then(res=>{
               this.getList()
               console.log(res)
           },error=>{
               console.log(error)
           })
        },
        AddClass(item, type){
            if(type != 1){
                this.currentId = item.id
            }
            this.classType = type
            this.parentClass.visible = true
        }
    }
}
</script>
<style lang="less" scoped>
.iconGroup{
    >*{
        padding: 0 12px;
        display: inline-block;
        vertical-align: middle;
    }
    .headImg{
        width: 36px;
        height: 36px;
        border-radius: 100%;
        background: #f07e66;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            display: block
        }
        &.noImg{
            background: #fff
        }
    }
    .processNum{
        color: #4165e0;
        width: 75px;
        font-weight: bold
    }
}
.iconGroup  .anticon{
    font-size:24px;
    color: #4165e0;
    cursor: pointer;
}
.btn{
    width: 105px;
}
.examList{
    margin-bottom:0;
    &.active{
        background: #5180e0;
        color: #fff;
        .num{
            color: #fff
        }
    }
}
.fL .btn{
    background: none;
    color: #5180e0;
    border: 1px solid #5180e0;
    &.active{
        color: #fff;
        background: #5180e0
    }
}
.sonList{
    margin: 0 20px;
    background: #fff;
    border: 1px dashed #5180e0;
    border-top: 0;
    padding: 0 20px;
    overflow: hidden;

}
.slide-fade-enter-active,.slide-fade-leave-active{
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-active {
    height: 30%;
    opacity: 0;
}
.transformIcon{
    transition: transform .3s ease-in-out;
    &.active{
        color: #fff;
        transform: rotateZ(90deg)
    }
}
.iconGroup{
    font-size: 16px
}
.sonItem{
    font-size: 16px;
    height: 60px;
    line-height: 60px;
    padding:0 20px;
    border-bottom: 1px dashed #5180e0;
    &:last-child{
        border-bottom: 0
    }
}
</style>